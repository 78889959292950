/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ReactToPrint from "react-to-print";
import axios from "axios";
import {
  Tables,
  TableTitle,
  EditButton,
  DeleteButton,
  NoButton,
  YesButton,
} from "./styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  TableFooter,
  TablePagination,

  Tooltip,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { theme } from "../../styles/Theme";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import QRCodePrint from "../../components/qrcodePrint";
import "animate.css";
import { CreateModal, EditModal } from "./Modais";
import { apidelete, apiget, apipost, apiput } from "../../Services/api";


const styleImg = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  alignSelf: "center",
  width: "35%",
  bgcolor: "background.paper",
  border: "2px solid #062e61",
  boxShadow: 24,
};
const styleQr = {
  padding: 10,
  display: "flex",
  justifyContent: "space-around",
  height: "100%",
  flexWrap: "wrap",
  bgcolor: "#062e61",

};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  border: "2px solid #062e61",
  boxShadow: 24,
  p: 4,
};

const URL_BASE = "https://biomob-api.com:3038/biomob-api";
export function Qrcode() {
  const [qrcodes, setQrcodes] = useState([]);
  const [client, setClient] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const [qrcodeToDelete, setqrcodeToDelete] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredQrcodes, setFilteredQrcodes] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedQrcodes, setSelectedQrcodes] = useState([]);
  const qrCodeListRef = useRef(null);

  const getClient = async () => {
    const user = await JSON.parse(localStorage.getItem("funcionario_info") ?? "{}");
    console.log("funci", user.funcionario_client)
   let cli = user.funcionario_client
      await setClient(cli);
  }
  useEffect(()=>{
    getClient()
  },[])
  
  useEffect(()=>{
    getQrcodes()
  },[client])

  const [qrcode, setQrcode] = useState({
    qrcodeId: "",
    descricao: "",
    codigo: "",
    foto: "",
    espec_tecnicas: "",
    title: "",
    pagamento: "",
    client:""
  });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenEdit = (qrcodeId) => {
    setQrcode(qrcodeId);
    setOpenEdit(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpenDelete = (qrcodeId) => {
    setqrcodeToDelete(qrcodeId);
    setOpenDelete(true);
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleCadastro = () => {
    postQrcode();
    handleClose();
    getQrcodes();
  };

  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 

  async function getQrcodes() {
    setLoading(true)
   await 
      apiget(`/qrcode/lista${"/"+client}`)
      .then(function (response) {
        console.log("resp", response)
        const qrcodes = response?.data?.content;
        setQrcodes(qrcodes);
        setLoading(false)

        return qrcodes;
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async function postQrcode() {
    try {
      setIsLoading(true);
      
      const response = await apipost("/qrcode", 
      {...qrcode, client:client}
      );
  
      if (response) {
        alert("Cadastro realizado com sucesso!");
         getQrcodes();
      } else {
        console.error("Erro na requisição:", response.status);
        alert("Ocorreu um erro ao cadastrar.");
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("Erro na função postQrcode:", error);
      alert("Ocorreu um erro ao cadastrar.");
    }
  }
  
  async function editarQrcode() {
    try {
      setIsLoading(true);
      apiput(`/qrcode/${qrcode.qrcodeId}`, {...qrcode, client:client})
        .then(function (response) { })
        .catch(function (error) {
          console.error(error);
        });
      alert("cadastro editado com sucesso!");
      getQrcodes();
      setIsLoading(false);
    } catch (error) {
      alert("Error");
    }
  }
  const handleEdit = (qrcodeId) => {
    editarQrcode(qrcodeId);
    handleClose();
  };

  async function deleteQrcode() {
    setIsLoading(true);
  
    try {
     
      await apidelete(`/qrcode/${qrcodeToDelete}`);
      alert("Qrcode deletado com sucesso!");
      
     
      getQrcodes();
    } catch (error) {
      console.error("Erro ao deletar o Qrcode:", error);
    
      alert("Você não tem permissão para excluir o QrCode.");
    } finally {
      setIsLoading(false);
      handleCloseDelete();
    }
  }
  
 


  const handleImageClick = (imageUrl) => {

    setModalImageUrl(imageUrl);
    setImageOpen(true);
  };
  const handlePrint = (selected) => {
    if (selected) {
      setSelectedQrcodes([]);
      handleFilter(); // Filter QR codes based on selected checkboxes
      setQrOpen(true);
    } else {
      setSelectedQrcodes(qrcodes.map((qrcode) => qrcode.qrcodeId));
    }
  };

  const handleFilter = () => {
    const selectedQrcodesData = qrcodes.filter((qrcode) =>
      selectedQrcodes.includes(qrcode.qrcodeId)
    );
    setFilteredQrcodes(selectedQrcodesData);
  };

  const handleSelectQrcode = (qrcodeId) => {
    if (selectedQrcodes.includes(qrcodeId)) {
      setSelectedQrcodes(selectedQrcodes.filter((id) => id !== qrcodeId));
    } else {
      setSelectedQrcodes([...selectedQrcodes, qrcodeId]);
    }
  };
  
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedQrcodes(qrcodes.filter((qrcode) => qrcode.client === client).map((qrcode) => qrcode.qrcodeId));
    } else {
      setSelectedQrcodes([]);
    }
  };
  

  return (
    <Tables>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <TableTitle>QR Codes</TableTitle>
        <Button
          sx={{
            backgroundColor: "#0A3B87",
            color: "white",
            borderRadius: "10px",
            "&:hover": {
              color: "green",
            },
          }}
          onClick={() => handleOpen()}
        >
          Cadastrar
        </Button>

        <Button
  sx={{
    backgroundColor: "#0A3B87",
    color: "white",
    borderRadius: "10px",
    "&:hover": {
      color: "green",
    },
  }}
  onClick={() => handlePrint(selectedQrcodes)}
>
  Imprimir selecionados
</Button>

      </div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, animation: "zoomInDown " }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: theme.colors.secondaryColor,
                color: theme.colors.white,
              }}
            >
              <TableCell style={{ color: theme.colors.white , padding:'15px'}} align="center">
                Imprimir
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                /> 
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Editar
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Excluir
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Título
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Descrição produto
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Espec Técnicas
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Descrição imagem
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Foto
              </TableCell>

              <TableCell style={{ color: theme.colors.white }} align="center">
                Qrcode url
              </TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">
                Data/Hora de Criação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loading ? ( 
      <TableRow>
      <TableCell colSpan={10} align="center">
      <div style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress />
        <Typography variant="body1" style={{ marginLeft: 10 }}>
          Aguarde, gerando Qr codes...
        </Typography>
      </div>
    </TableCell>
    </TableRow>
       
      ) : (<>
          {qrcodes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((qrcode, index) => (
                <TableRow
                  key={qrcode?.codigo}
                  style={{
                    animation: ` fadeInRightBig ${index * 0.3}s`,
                    backgroundColor: index % 2 === 0 ? "#FFF" : "#F0F0F0",
                  }}
                >
                  <TableCell align="center">
                    <Tooltip title="Imprimir Qrcode" placement="top">
                    <Checkbox
      checked={selectedQrcodes.includes(qrcode.qrcodeId)}
      onChange={() => handleSelectQrcode(qrcode.qrcodeId)}
    />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <EditButton onClick={() => console.log("click")}>
                      <EditIcon
                        sx={{ color: "#fff", fontSize: 25 }}
                        onClick={() => handleOpenEdit(qrcode)}
                      />
                    </EditButton>
                  </TableCell>
                  <TableCell align="center">
                    <DeleteButton onClick={() => console.log("click")}>
                      <DeleteIcon
                        sx={{ color: "#fff", fontSize: 25 }}
                        onClick={() => handleOpenDelete(qrcode.qrcodeId)}
                      />
                    </DeleteButton>
                  </TableCell>
                  <TableCell align="left">
                    {qrcode?.title.length > 10 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        
                        {qrcode?.title.substring(0, 30) + "..."}
                      </div>
                    ) : (
                      qrcode?.title
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {qrcode?.pagamento.length > 10 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                    
                
                        {qrcode?.pagamento.substring(0, 30) + "..."}
                      </div>
                    ) : (
                      qrcode?.pagamento
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {qrcode?.espec_tecnicas.length > 10 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                     
                        {qrcode?.espec_tecnicas.substring(0, 30) + "..."}
                      </div>
                    ) : (
                      qrcode?.espec_tecnicas
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {qrcode?.descricao.length > 10 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                      
                        {qrcode?.descricao.substring(0, 30) + "..."}
                      </div>
                    ) : (
                      qrcode.descricao
                    )}
                  </TableCell>

                  <TableCell align="left">
                    {!!qrcode?.foto ? (
                      <Tooltip
                        title="Clique para abrir a imagem"
                        placement="top"
                      >
                        <img
                          alt="detalhe foto"
                          src={`${qrcode?.foto}`}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => handleImageClick(qrcode?.foto)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Foto não cadastrada" placement="top">
                        <span style={{ cursor: "default" }}>
                          foto não cadastrada
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>



                  <TableCell align="left" component="th" scope="qrcode">
                    <Tooltip
                      title="Clique para abrir a página do produto"
                      placement="top"
                    >
                      <button
                        style={{
                          borderRadius: 90,
                          padding: 10,
                          border: "none",
                        }}
                        onClick={() => navigate(`/produto/${qrcode.qrcodeId}`)}
                      >
                        {"https://diversidadefuncional.com/produto/" + qrcode.qrcodeId}
                      </button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{qrcode?.dataHoraCriacao}</TableCell>
                </TableRow>
              ))}</>)}
          </TableBody>
        </Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Ver tudo", value: -1 }]}
              colSpan={3}
              count={qrcodes?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Linhas por página",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </TableContainer>

      <EditModal
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        isLoading={isLoading}
        qrcode={qrcode}
        setQrcode={setQrcode}
        handleEdit={handleEdit}
      />

      <CreateModal
        open={open}
        handleClose={handleClose}
        isLoading={isLoading}
        qrcode={qrcode}
        setQrcode={setQrcode}
        handleCadastro={handleCadastro}
      />



      {/* ///modal exibe imagem fotos */}
      <Modal
        open={imageOpen}
        onClose={() => {
          setImageOpen(false);
        }}
      >
        <Box sx={styleImg}>
          <img style={{ width: "80%", height: "80%" }} src={modalImageUrl} />
        </Box>
      </Modal>
      {/* ///modal exibe imagem qrcodes*/}
      <>
        <Modal
          open={qrOpen}
          onClose={() => {
            setQrOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleQr}>
            <div style={{ margin: 5, width: "100%", display: 'flex', justifyContent: 'space-between' }}>
              <ReactToPrint
                trigger={() => <Button style={{ color: '#fff' }}>Imprimir </Button>}
                content={() => qrCodeListRef.current}
              />
              <Button style={{ color: '#fff' }} onClick={() => setQrOpen(false)}>Voltar</Button>
            </div>

            {/* Renderize o componente QRCodeListPrint dentro do ref */}
            <div style={{
              backgroundColor: '#ffff', width: "100%", margin: '25px 5px 15px 5px', display: 'flex', overflow: 'auto',
              flexWrap: "wrap", height: '100%', padding: '25px 5px 25px 5px'
            }} ref={qrCodeListRef}>
              {filteredQrcodes.map((qr, index) => (
                <div style={{ margin: 25 }} key={qr.qrcodeId + index}>
                  <QRCodePrint qrcodeId={qr.qrcodeId} codigo={qr.codigo} titulo={qr.title} />
                </div>
              ))}
            </div>

            <div style={{ margin: 25, width: "100%" }}>

            </div>
          </Box>
        </Modal>
      </>

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Box sx={styleDelete}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Deletar Qrcode
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Deseja realmente deletar esse Qrcode?
            </Typography>
            <NoButton onClick={handleCloseDelete}>Não</NoButton>
            <YesButton onClick={() => deleteQrcode()}>Sim</YesButton>
          </Box>
        )}
      </Modal>
    </Tables>
  );
}
