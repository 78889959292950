import axios from "axios";

const URL_CADASTRO = "https://biomob-api.com:3038/biomob-api";
const JWT_LOCAL_STORAGE_KEY = "funcionario_info"; 

const api = axios.create({
  baseURL: URL_CADASTRO,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getToken = () => {
  const user = JSON.parse(localStorage.getItem(JWT_LOCAL_STORAGE_KEY)) || {};
  return user.funcionario_token || "";
};

// Rest of the interceptors and methods remain unchanged...

export const apiget = (url, payload) => {
  return api.get(url, { payload });
};
export const apipatch = async (url, payload) => await api.patch(url, payload);
export const apipost = async (url, payload) => await api.post(url, payload);
export const apidelete = async (url, payload) => await api.delete(url, payload);
export const apiput = async (url, payload) => await api.put(url, payload);

export default api;
