import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../assets/logopreto.png';
import LogoGiratorio from '../../assets/tim_giratorio.gif';
import { Image } from './styles';
import { useNavigate } from 'react-router-dom';
import { GlobalStyle } from '../../styles/global';
import { theme } from '../../styles/Theme';



const pages = [

  { id: 2, description: 'Operadores' },
  { id: 3, description: 'Qrcodes' },
  { id: 6, description: 'Beacons' },

];


export function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [nivel, setNivel] = React.useState<boolean | HTMLElement>(false);
  const [token, setToken] = React.useState<boolean | HTMLElement>(false);
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = React.useState<number | null>(null);
  const getNivel = async () => {
    const user = await JSON.parse(localStorage.getItem("funcionario_info") ?? "{}") as any;
    console.log("func", user.funcionario_nivel)
    if (user.funcionario_nivel == 1) {
      setNivel(true);

    }

  }
  const geToken = async () => {

    const user = await JSON.parse(localStorage.getItem("funcionario_info" ?? "{}") as any);
    console.log("token", user.funcionario_token)
    let jwt = user.funcionario_token;
    if (jwt != null) {
      setToken(true)
    } else {
      setToken(false)
    }
  }

  React.useEffect(() => {
    getNivel();
    geToken();
  }, [])

  const sair = () => {
    localStorage.clear();
    navigate("/")
    window.location.reload();
  }


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function handleNavigation(page: number): void {


    switch (page) {
      case 1: navigate("/");
        break;
      case 2: navigate("/user");
        break
        case 3: navigate("/qrcode");
        break
      case 6: navigate("/beacons");
        break;

    }
  };
  const filteredPages = pages.filter((page) => {
    // If nivel is true, include the "Beacons" page, otherwise exclude it
    return page.id !== 6 && page.id !== 2 || nivel;
  });

  return (
    <AppBar position="static" sx={{
      backgroundColor: theme.colors.secondaryColor,

    }}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters>
          <Image src={LogoGiratorio} alt="Logo tim audio guia" />

          {token &&
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {filteredPages.map((page) => (
                  <MenuItem key={page.id} onClick={() => handleNavigation(page.id)}>

                    <Typography textAlign="center">{page.description}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }

          {token && <>
            <Box
  sx={{
    flexGrow: 1,
    display: { xs: 'none', md: 'flex' },
    justifyContent: "space-around",
  }}
>
  {filteredPages.map((page) => (
    <Button
      key={page.id}
      onClick={() => handleNavigation(page.id)}
      onMouseEnter={() => setHoveredButton(page.id)}
      onMouseLeave={() => setHoveredButton(null)}
      sx={{
        borderRadius:"10",
        my: 2,
        color: 'white',
        display: 'block',
        transform: hoveredButton === page.id ? 'scale(1.2)' : 'scale(1)',
        transition: 'transform 0.3s ease',
      }}
    >
      {page.description}
    </Button>
  ))}
</Box>
            <Button
              onClick={sair}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Sair
            </Button>

          </>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
