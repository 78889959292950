
export const theme = {
  colors: {
    mainColor: '#A9D9D0',
   
    secondaryColor: '#0A3B87',
    secondaryColorWithOpacity: '#0A3B87',
  
    black: '#0D0D0D',
    white: '#F2E7DC',
    mainColorButtonHover: '#8E7DF5',
    warningColor: '#F29305',
    dangerColor: 'rgba(204, 33, 10, 1)',
    redTim: '#D80A20',

    green: '#219326',
  },
  fonts: {},
  spacings: {},
};