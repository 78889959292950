import { Header } from "./components/Header";
import { GlobalStyle } from "./styles/global";
import { Routes, Route } from "react-router-dom";
import { User } from "./pages/User";
import LoginMaster from "./pages/Login";
import RequireAuth from "./Utils/RequireAuth";
import { Qrcode } from "./pages/Qrcode";
import { Beacon } from "./pages/Beacons";
import Produto from "./pages/Produto";
import Termo from "./pages/Termo";

export function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/"
         element={
          <>
        <GlobalStyle /> 
        <LoginMaster />
        </>} />
        <Route
          path="/user"
          element={
            <RequireAuth>
                <GlobalStyle />
               <Header />
              <User />
            </RequireAuth>
          }
        />
        <Route
          path="/beacons"
          element={
            <RequireAuth>
                <GlobalStyle />
               <Header />
              <Beacon />
            </RequireAuth>
          }
        />
        <Route
          path="/qrcode"
          element={
            <RequireAuth>
                <GlobalStyle />
               <Header />
              <Qrcode />
            </RequireAuth>
          }
        />  <Route
        path="/termo"
        element={
          <RequireAuth>
              <GlobalStyle />
            <Termo />
          </RequireAuth>
        }
      />
        <Route  path="/produto/:qrcodeId" element={<Produto />} />
      </Routes>
    </div>
  );
}
