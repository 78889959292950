import { useRef } from 'react';
import QRCode from 'qrcode.react';
import imgQr from '../assets/logoQr.jpg';

function QRCodePrint({ qrcodeId, codigo, titulo }) {
  const componentRef = useRef(null);
  return (
    <div>
      <div id="qrcode-container" ref={componentRef}>
        <div style={{display:'flex',
      justifyContent:'space-between'}}>
        <p style={{ fontWeight:600,  fontSize: 7 , width:90}}>{titulo}</p>
        <img
            style={{ width: "20px", alignSelf: "center", }}
            src={imgQr}
            alt="logo Qrcode"
          />
        </div>
      <p style={{ fontSize: 7, alignSelf:'center' }}>{codigo}</p>
        <QRCode value={"https://diversidadefuncional.com/produto/" + qrcodeId} size={130} />
    
      </div>
    </div>
  );
}

export default QRCodePrint;
