import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from '../../assets/Logo.png';
import { apiget } from "../../Services/api";
import axios from "axios";

const Produto = () => {
  const [produto, setProduto] = useState(null);
  const { qrcodeId } = useParams();
  const [isReaderActive, setIsReaderActive] = useState(false);
  const messageActive = "Leitor de tela ativado!";
  const messageInactive = "Leitor de tela desativado, para ativar ou desativar clique aqui!"
  let speechSynthesisUtterance;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (qrcodeId) {
          const response = await axios.get(
            `https://biomob-api.com:3038/biomob-api/qrcode/${qrcodeId}`
          );
          setProduto(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [qrcodeId]);

  if (!produto) {
    return <div>Carregando...</div>;
  }

  const handleButtonClick = () => {
    if (isReaderActive) {
      window.speechSynthesis.cancel();
      setIsReaderActive(false);
    } else {
      const contentToSpeak = `${produto.title}. ${produto.pagamento}. ${produto.espec_tecnicas}.${produto.descricao}`;
      speechSynthesisUtterance = new SpeechSynthesisUtterance(contentToSpeak);

      window.speechSynthesis.speak(speechSynthesisUtterance);
      setIsReaderActive(true);
    }
  };

  return (
    <div
       onClick={handleButtonClick}
      aria-label="Tela com leitor de conteudo em voz alta"
    >
      <div style={{
        width: "100%", height: 50, backgroundColor: "#0d3c83", border: "none",
        borderRadius: '5px', display: 'flex', justifyContent: 'flex-start'
      }}> 
       <img style={{padding:8}} src={Logo} alt="Logo tim audio guia" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: '3px'

        }}>
        <button
          onClick={handleButtonClick}
          onKeyPress={handleButtonClick}
          style={{
            background: "#0d3c83",
            border: "none",
            borderRadius: '5px',
            width: '400px',
            margin: '0 auto',
            cursor: "pointer",
            fontSize:'12px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }} aria-label="Ativar leitor de tela">
          {isReaderActive ? <p style={{ color: '#fff' }}>{messageActive}</p> : <p style={{ color: '#fff' }}>{messageInactive}</p>}
        </button>
      </div>

      <div
        style={{
          borderRadius: '5px',
      
          color: 'black',
          padding: '20px',
          background: '#fff',
          maxWidth: '400px',
          margin: '0 auto',
          minHeight: '700px',

        }}
        ariaLive="assertive"
      >

        <h1>{produto?.title}</h1>
        <h3>{produto?.pagamento}</h3>
        <p>{produto?.espec_tecnicas}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ width: "250px", alignSelf: "center", }}
            src={produto?.foto}
            alt={produto?.descricao}
          />
        </div>

      </div>
    </div>
  );
};

export default Produto;
