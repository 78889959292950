import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { InputLogin } from "../../styles/Styles";
import LogoGiratorio from "../../assets/tim_giratorio.gif";
import Loading from "../../assets/tim_loading.gif";
import Logo from "../../assets/logopreto.png";
import "../../styles/Fontes.css";
import { Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../Services/api";
import { theme } from "../../styles/Theme";

function LoginMaster() {
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const entrar = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("funcionario_info"));
  
      if (user.funcionario_role=="USER"   || user.funcionario_role=="ADMIN"    ) {
        if (user.funcionario_termo_priv) {
          alert("Bem-vindo! Acesso concedido.");
          navigate("/qrcode");
        } else {
          alert("Você precisa aceitar os termos para acessar.");
          navigate("/termo", { state: { prop: "termosTim/privacidade" } });
          
        }
      } else {
        alert("Acesso negado. Você não tem permissão para entrar.");
        navigate("/");
      }
    } catch (error) {
      console.error("Erro ao verificar o usuário:", error);
      alert("Ocorreu um erro ao verificar o usuário. Por favor, tente novamente.");
      navigate("/");
    }
  };
  
  
  

  const LoginUser = async () => {
    setLoading(true);

    // Função de atraso
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      const response = await fetch(
        "https://biomob-api.com:3038/biomob-api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            login: login,
            password: senha,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        localStorage.setItem(
          "funcionario_info",
          JSON.stringify({
            funcionario_login: result.user.login,
            funcionario_nome: result.user.name,
            funcionario_telefone: result.user.telefone,
            funcionario_email: result.user.email,
            funcionario_doc: result.user.documento,
            funcionario_nivel: result.user.nivel,
            funcionario_role: result.user.role,
            funcionario_token: result.token,
            funcionario_client: result.user.client,
            funcionario_termo_resp: result.user.termoDeResponsabilidadeTimAceito,
            funcionario_termo_priv: result.user.termoDePrivacidadeTimAceito,
          })
        );

        // Atraso de 2 segundos
        await delay(2000);

        setLoading(false);
        if (result.user.login !== "") {
          entrar();
        } else {
          navigate("/");
          alert("Email ou senha incorretos");
        }
      } else {
        setLoading(false);
        navigate("/");
        alert("Email ou senha incorretos");
      }
    } catch (error) {
      setLoading(false);
      navigate("/");
      alert("Erro ao fazer login");
    }
  };

  // const recuperar = (e) => {
  //     e.preventDefault();
  //     const usuario = { email };
  //     api.post("/usuario-senha", usuario).then((response) => {
  //         alert("Verifique sua caixa de email (spam e lixeira)");
  //         navigate(`/`);
  //     }).catch((err) => {
  //         alert('Email não encontrado')
  //     })
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        backgroundSize: "contain",
        height: "95vh",
        marginTop: "20px",
        opacity: 0.9,
      }}
    >
      <CardContent
        sx={{
          borderRadius: "20px",
          backgroundColor: theme.colors.secondaryColorWithOpacity,
          boxShadow: "24px 23px 55px -3px rgba(0,0,0,0.8)",
        }}
      >
        <>
          <Box
            style={{
              maxWidth: "80vw",
              display: loading ? "none" : "flex",
              borderRadius: "20px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.colors.secondaryColorWithOpacity,
            }}
          >
            <img
              src={Logo}
              style={{
                marginTop: "5px",
                marginBottom: "0px",
                align: "center",
                width: "200px",
              }}
              alt="Logo Vmob"
            />
          </Box>
          <Typography
            component="div"
            align="center"
            marginTop="5px"
            marginBottom="20px"
            color={theme.colors.white}
            fontWeight="bold"
            fontSize={12}
          >
            TIM ÁUDIO GUIA®
          </Typography>
        </>
        {loading ? (
          <Container>
            <Box
              style={{
                display: "flex",
                borderRadius: "20px",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.colors.secondaryColorWithOpacity,
              }}
            >
              <img
                src={Loading}
                style={{
                  marginTop: "25px",
                  marginBottom: "0px",
                  align: "center",
                  width: "300px",
                }}
                alt="Logo Vmob"
              />
            </Box>
          </Container>
        ) : (
          <>
            <Container>
              <InputLogin
                className="inputRounded"
                variant="filled"
                label="Login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root:hover": {
                    "& fieldset": {
                      borderColor: "#cfe9ff",
                      fontFamily: "Poppins",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#cfe9ff",
                      fontFamily: "Poppins",
                    },
                  },
                  "& .MuiInputBase-input:-webkit-autofill": {
                    "-webkit-text-fill-color": "black",
                    "-webkit-box-shadow": "0 0 0 1000px white inset",
                    fontFamily: "Poppins",
                  },
                }}
              />
            </Container>
            <Container>
              <InputLogin
                sx={{
                  marginTop: "5vh",
                  marginBottom: "5vh",
                  "& .MuiOutlinedInput-root:hover": {
                    "& fieldset": {
                      borderColor: "#cfe9ff !important",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#cfe9ff !important",
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "#cfe9ff !important",
                    },
                  },
                  "& .MuiInputBase-input:-webkit-autofill": {
                    "-webkit-text-fill-color": "black !important",
                    "-webkit-box-shadow": "0 0 0 1000px white inset !important",
                  },
                }}
                className="inputRounded"
                variant="filled"
                label="Senha"
                type="password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
            </Container>
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => LoginUser()}
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  height: "50px",
                  backgroundColor: theme.colors.redTim,
                  color: "#fff",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: "150px",
                }}
                variant="contained"
              >
                LOGIN
              </Button>
            </Container>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default LoginMaster;
