// EditModal.js

import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { RegisterButton } from "../styles";
import { Tooltip } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

export const EditModal = ({
  open,
  handleClose,
  isLoading,
  qrcode,
  setQrcode,
  handleEdit,
}) => {
  const style = {
    // position: "absolute",

    width: "100%",
    overflow: 'scroll',
    bgcolor: "background.paper",
    border: "2px solid #062e61",
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    height: '100%'
  };
  const [isSpeaking, setIsSpeaking] = useState(false);
  const toggleSpeech = (text) => {
    if ("speechSynthesis" in window) {
      if (isSpeaking) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
      } else {
        const speech = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.speak(speech);
        setIsSpeaking(true);
      }
    }
  };
  const [base64Image, setBase64Image] = useState("");
 

  useEffect(() => {
    // Whenever base64Image changes, update qrcode.foto
    setQrcode((prevQrcode) => ({
      ...prevQrcode,
      foto: base64Image,
    }));
  }, [base64Image]);
  

const handleFileChange = (file) => {
  if (!file) return;

  const reader = new FileReader();
  reader.onloadend = () => {
    setBase64Image(reader.result);
  };
  reader.readAsDataURL(file);
};

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Box sx={{ ...style }}>
          <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h5" component="h3">
            Editar Qrcode
          </Typography>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          ><div  style={{ width: '90%' }}>
              <TextField
                autoFocus
                multiline
                style={{ width: '90%' }}
                minRows={4}
                maxRows={8}
                id="outlined-basic"
                label="Título"
                variant="outlined"
                onChange={(e) => setQrcode({ ...qrcode, title: e.target.value })}
                value={qrcode.title}
              /> <Tooltip title="Ouvir Título" placement="top">
                <IconButton
                  onClick={() => toggleSpeech(qrcode.title)}
                >
                  <VolumeUpIcon />
                </IconButton>
              </Tooltip>
            </div>
             <div style={{width:'90%'}}>
             <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Descrição produto"
              placeholder="Digite a descrição produto..."

              style={{ width: '90%' }}
              onChange={(e) => setQrcode({ ...qrcode, pagamento: e.target.value })}
              value={qrcode.pagamento}
              variant="outlined"
            /><Tooltip title="Ouvir Descrição" placement="top">
            <IconButton
              onClick={() => toggleSpeech(qrcode.pagamento)}
            >
              <VolumeUpIcon />
            </IconButton>
          </Tooltip>
             </div>
            <div  style={{ width: '90%' }}>
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Descrição imagem"
              style={{ width: '90%' }}
              variant="outlined"
              onChange={(e) =>
                setQrcode({ ...qrcode, descricao: e.target.value })
              }
              value={qrcode.descricao}
            /><Tooltip title="Ouvir Descrição imagem" placement="top">
            <IconButton
              onClick={() => toggleSpeech(qrcode.descricao)}
            >
              <VolumeUpIcon />
            </IconButton>
          </Tooltip>
            </div>
          <div   style={{ width: '90%' }}>
            <TextField
              autoFocus
              multiline
              style={{ width: '90%' }}
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Espec técnicas"
              variant="outlined"
              onChange={(e) =>
                setQrcode({ ...qrcode, espec_tecnicas: e.target.value })
              }
              value={qrcode.espec_tecnicas}
            /><Tooltip title="Ouvir Especificações" placement="top">
            <IconButton
              onClick={() => toggleSpeech(qrcode.espec_tecnicas)}
            >
              <VolumeUpIcon />
            </IconButton>
          </Tooltip></div>
          
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Codigo"
              variant="outlined"
              onChange={(e) => setQrcode({ ...qrcode, codigo: e.target.value })}
              value={qrcode.codigo}
            />
              <TextField
            
              style={{ width: '400px' }}
             
              type="file"
              accept="image/*"
              variant="outlined"
              onChange={(e) => handleFileChange(e.target.files[0])}
            />
          </Box>
          <RegisterButton onClick={() => handleEdit(qrcode.qrcodeId)}>
            Editar
          </RegisterButton>
        </Box>
      )}
    </Modal>
  );
};


export const CreateModal = ({
  open,
  handleClose,
  isLoading,
  qrcode,
  setQrcode,
  handleCadastro,
}) => {
  const style = {

    width: "100%",
    overflow: 'scroll',
    bgcolor: "background.paper",
    border: "2px solid #062e61",
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    height: '100%'
  };
  const [base64Image, setBase64Image] = useState("");
  
  useEffect(() => {
    // Whenever base64Image changes, update qrcode.foto
    setQrcode((prevQrcode) => ({
      ...prevQrcode,
      foto: base64Image,
    }));
  }, [base64Image]);
  
  const handleFileChange = (file) => {
    if (!file) return;
  
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Box sx={style}>
          <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h5" component="h3">
            Cadastrar Qrcode
          </Typography>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Título"
              variant="outlined"
              onChange={(e) => setQrcode({ ...qrcode, title: e.target.value })}
              value={qrcode.title}
            />
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              unselectable="off"
              id="outlined-basic"
              label="Descrição produto"
              variant="outlined"
              onChange={(e) =>
                setQrcode({ ...qrcode, pagamento: e.target.value })
              }
              value={qrcode.pagamento}
            />
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Descrição imagem"
              variant="outlined"
              onChange={(e) => setQrcode({ ...qrcode, descricao: e.target.value })}
              value={qrcode.descricao}
            />
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Espec técnicas"
              variant="outlined"
              onChange={(e) =>
                setQrcode({ ...qrcode, espec_tecnicas: e.target.value })
              }
              value={qrcode.espec_tecnicas}
            />
          
            <TextField
              autoFocus
              multiline
              minRows={4}
              maxRows={8}
              id="outlined-basic"
              label="Codigo"
              variant="outlined"
              onChange={(e) => setQrcode({ ...qrcode, codigo: e.target.value })}
              value={qrcode.codigo}
            />
              <TextField
              type="file"
              accept="image/*"
              style={{ width: '400px' }}
              onChange={(e) => handleFileChange(e.target.files[0])}
            />
          </Box>
          <RegisterButton onClick={handleCadastro}>Cadastrar</RegisterButton>
        </Box>
      )}
    </Modal>
  );
};

