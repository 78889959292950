import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from '../../assets/Logo.png';
import { apiget, apipatch } from "../../Services/api";
import axios from "axios";
import { Button, Card } from "@mui/material";

const Termo = () => {
  const [termo, setTermo] = useState('');
  const { qrcodeId } = useParams();
  const [isReaderActive, setIsReaderActive] = useState(false);
  const location = useLocation();
  const url = location.state?.prop;

  // Restante do seu código

 

  useEffect(() => {
    const fetchData = async () => {
      try {
       
          const response = await apiget(url);
          setTermo(response.data.conteudo);
        
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const AceitoTermo = async () => {
    try {
      if (url === "termosTim/privacidade") {
        await apipatch('user/termoPrivacidadeTim');
        navigate('/qrcode');
      } else if (url === "termosTim/responsabilidade") {
        await apipatch('user/termoResponsabilidadeTim');
        localStorage.setItem(
          "funcionario_info",
          JSON.stringify({
            funcionario_termo_resp: true,
          })
        ); // Adicione o parêntese de fechamento aqui
        navigate('/user');
      }
    } catch (error) {
      navigate('/user');
    }
  };
  


  
  
  const navigate = useNavigate();
  const TextoFormatado = () => {
    return (
      <div style={{ whiteSpace: "pre-wrap" }}>
        {termo}
      </div>
    );
  };
  return (
   <div >
      <div style={{
        width: "100%", height: 50, backgroundColor: "#0d3c83", border: "none",
        borderRadius: '5px', display: 'flex', justifyContent: 'flex-start'
      }}> 
       <img style={{padding:8}} src={Logo} alt="Logo tim audio guia" />
      </div>
      <div style={{width:"100%",  display: "flex",
        justifyContent: "center",}}>
      <Card
      sx={{
        marginTop:5,
        textAlign: "justify",
        padding: 10,
        width:"50%",
        marginBottom:2
      }}
   >
    <h2>{url === "termosTim/privacidade" ? "Termo de privacidade" : "Termo de responsabilidade"}</h2>
    <br/>
    <div>
        <pre>{TextoFormatado()}
</pre>
    </div>
   </Card>
       
      </div>
      <div style={{
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  paddingBottom:20
}}>
  <Button onClick={()=> AceitoTermo()} style={{ backgroundColor: "#0A3B87", color: "white" }}>Aceito termo</Button>
  <Button onClick={ ()=> navigate("/")} style={{ backgroundColor: "#D80A20", color: "white" }}>Voltar</Button>
</div>

      </div>
   
  );
};

export default Termo;
