/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Tables, TableTitle, EditButton, DeleteButton, RegisterButton, NoButton, YesButton } from './styles';
import { Button, TableFooter, TablePagination } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { theme } from '../../styles/Theme';
import  { apidelete, apiget, apipost } from '../../Services/api';
import { obfuscateEmail } from '../../Utils/obfuscateEmail';
import { useNavigate } from 'react-router-dom';


interface Users {
  email: string,
  login: string,
  id: number,
  name: string,
  cpf: string,
  telefone: string,
  password: string,
  client:string,
  role: string,
  nivel: number,
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: '2px solid #36B396',
  boxShadow: 24,
  p: 4,
};

const styleDelete = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 280,
  bgcolor: 'background.paper',
  border: '2px solid #36B396',
  boxShadow: 24,
  p: 4,
};


export function User() {

  const [users, setUsers] = React.useState<Users[]>([]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDelete = (id: any) => {
    setEmailToDelete(id);
    setOpenDelete(true)
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const [buttonTitle, setButtonTitle] = React.useState('');
  const [modalTitle, setModalTitle] = React.useState('');
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [nivel, setNivel] = React.useState(2);
  const [telefone, setTelefone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailToDelete, setEmailToDelete] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [client, setClient] = React.useState('');
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("funcionario_info" ?? "{}") as any);
  let admin = user.funcionario_nivel;
 

  const getClient = async () => {
    const user = await JSON.parse(localStorage.getItem("funcionario_info") ?? "{}") as any;
      setClient(user.funcionario_client);
  }
  React.useEffect(()=>{
    getClient()
  },[])
  
  React.useEffect(()=>{
    getUsers()
  },[client])
 

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const body = {
    email,
    name,
    login: email,
    // cpf,
    client:client,
    // telefone,
    nivel,
    password,
    role:'USER'
  }

  async function getUsers() {
    await apiget(`/user/list${"/"+client}`)
      .then(function (response) {
        const users = response?.data.content as Users[];
        setUsers(users);
        return users;
      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      })
      .then(function () {
        // sempre será executado
      });
  }


  async function postUsers() {
    if (user.funcionario_termo_resp) {
      try {
        await apipost('/auth/register', body)
        alert("Cadastro efetuado com sucesso!")
      } catch (error) {
        alert("Erro ao cadastrar!")
      }
     
    }  if (!user.funcionario_termo_resp) {
      alert("Você precisa aceitar os termos para acessar.");
      navigate("/termo", { state: { prop: "termosTim/responsabilidade" } });
  }
  
}



  async function deleteUsers() {
    try {
      await apidelete(`/user/${emailToDelete}`);
      setEmailToDelete('');
      await getUsers();
      handleCloseDelete();
      alert("Usuário excluído com sucesso!", )
    } catch (error) {
    alert("falha ao deletar", )
      handleCloseDelete();
    }
  }

  function editCreate(action: String, emailEdit?: String) {
    if (action === 'edit') {
      setModalTitle('Editar usuário')
      setButtonTitle('Atualizar')
    } else {
      setModalTitle('Cadastrar usuário')
      setButtonTitle('Cadastrar')
    }
    users.filter(e => e.email === emailEdit).map((e) => {
      setName(e.name)
      // setCpf(e.cpf)
      setEmail(e.email)
      // setTelefone(e.telefone)
      setPassword(e.password)
      setNivel(e.nivel)
      setClient(client)
    });
    handleOpen();
  }


  const [modalImageUrl, setModalImageUrl] = React.useState("");
  const [imageOpen, setImageOpen] = React.useState(false);



  return (
    <Tables>
      <TableTitle>Operadores</TableTitle>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: theme.colors.secondaryColor, color: theme.colors.white }}>
              <TableCell style={{ color: theme.colors.white }} align="left">Nome</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">E-mail</TableCell>
              {/* <TableCell style={{ color: theme.colors.white }} align="left">CPF</TableCell> */}
              {/* <TableCell style={{ color: theme.colors.white }} align="left">Telefone</TableCell> */}
              <TableCell style={{ color: theme.colors.white }} align="left">Nivel</TableCell>
              {/* <TableCell style={{ color: theme.colors.white }} align="left">Senha</TableCell> */}
              {admin === 1 && <>
                <TableCell style={{ color: theme.colors.white }} align="left">Editar</TableCell>
                <TableCell style={{ color: theme.colors.white }} align="left">Excluir</TableCell></>}
            </TableRow>
          </TableHead>
          <TableBody>
          {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                <TableRow
                  key={user?.name}
                  style={{ animation: ` fadeInRightBig ${index * 0.5}s`, backgroundColor: index % 2 === 0 ? "#FFF" : "#F0F0F0" }}

                >
                  <TableCell align="left" component="th" scope="user">
                    {user?.name}
                  </TableCell>
                  <TableCell align="left" >{obfuscateEmail(user?.email)}</TableCell>
                  {/* <TableCell align="left">{user?.cpf}</TableCell> */}
                  {/* <TableCell align="left">{user?.telefone ? user?.telefone : 'Não informado'}</TableCell> */}
                  <TableCell align="left">{user?.nivel}</TableCell>
                  {/* <TableCell align="left">{user?.password}</TableCell> */}
                  {admin === 1 && <>
                    <TableCell align="left">
                      <EditButton onClick={() => editCreate('edit', user?.email)}>
                        <EditIcon sx={{ color: '#fff' }} />
                      </EditButton>
                    </TableCell>
                    <TableCell align="left">
                      <DeleteButton onClick={() => handleOpenDelete(user?.email)}>
                        <DeleteIcon sx={{ color: '#fff' }} />
                      </DeleteButton>
                    </TableCell></>}
                </TableRow>

              ))}
          </TableBody>
        </Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Ver tudo", value: -1 }]}
              colSpan={3}
              count={users?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Linhas por página",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>

      </TableContainer>
      {admin === 1 && <>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
          <Button sx={{
            backgroundColor: "#30A54A",
            color: "white",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }} onClick={() => { editCreate('create') }}>+ Cadastrar
          </Button >
        </div>
      </>

      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {modalTitle}
          </Typography>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },

            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              autoFocus
              unselectable='off'
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            {/* <TextField
              id="outlined-basic"
              label="CPF"
              variant="outlined"
              onChange={e => setCpf(e.target.value)}
              value={cpf}
            /> */}

            <TextField
              id="outlined-basic"
              required label="E-mail"
              variant="outlined"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />

            {/* <TextField
              id="outlined-basic"
              label="Telefone"
              variant="outlined"
              onChange={e => setTelefone(e.target.value)}
              value={telefone}
            /> */}

            <TextField
              id="outlined-basic"
              label="Senha"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />


            <RegisterButton onClick={postUsers}>
              {buttonTitle}
            </RegisterButton>
          </Box>
        </Box>
      </Modal>
      {/* ///modal exibe imagem fotos */}
      <Modal
        open={imageOpen}
        onClose={() => { setImageOpen(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img style={{ width: '80%', height: '80%' }} src={modalImageUrl} />
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Deletar Usuário
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deseja realmente deletar esse usuário?
          </Typography>
          <NoButton onClick={handleCloseDelete}>
            Não
          </NoButton>
          <YesButton onClick={() => deleteUsers()}>
            Sim
          </YesButton>

        </Box>
      </Modal>

    </Tables>
  );
}
