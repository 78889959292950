export function obfuscateEmail(email) {
  if (!email) return '';

  const parts = email.split('@');
  if (parts.length !== 2) return email; // Retorna o e-mail original se não estiver no formato esperado

  const username = parts[0];
  const domain = parts[1];

  const visiblePart = username.substring(0, 3); // Pega as 3 primeiras letras do nome de usuário
  const obfuscatedPart = new Array(username.length - 2).join('*'); // Cria uma string de asteriscos

  return `${visiblePart}${obfuscatedPart}@${domain}`;
}

  