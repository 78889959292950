import React, { useEffect, useState } from 'react';
import { AddButton, Tables, TableTitle, EditButton, DeleteButton, RegisterButton, NoButton, YesButton } from './styles';
import { Button, CircularProgress, IconButton, TableFooter, TablePagination } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { theme } from '../../styles/Theme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { apidelete, apiget, apipost, apiput } from '../../Services/api';


const style = {


  width: "100%",
  overflow: 'scroll',
  bgcolor: "background.paper",
  border: "2px solid #0A3B87",
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  height: '100%'

};

const styleDelete = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 280,
  bgcolor: 'background.paper',
  border: '2px solid #0A3B87',
  boxShadow: 24,
  p: 4,
};


const URL_BASE = "https://177.70.102.109:3007/biomob-api";

export function Beacon() {
  const [beacons, setBeacons] = useState([]);
 

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const [buttonTitle, setButtonTitle] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [beaconId, setBeaconId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [client, setClient] = useState('');
 

  const handleChangePage = (
    event,
    newPage
  ) => {
    setPage(newPage);
  };
  const getClient = async () => {
    const user = await JSON.parse(localStorage.getItem("funcionario_info") ?? "{}");
    console.log("funci", user.funcionario_client)
   let cli = user.funcionario_client
     setClient(cli);
  }
  useEffect(()=>{
    getClient()
  },[])

  useEffect(()=>{
    getBeacons()
  },[client])

  const [beacon, setBeacon] = useState({
    beaconId: "",
    descricao: "",
    nome: "",
    foto: "",
    lat: 0,
    lng: 0,
    norte: "",
    nordeste: "",
    leste: "",
    sudeste: "",
    sul: "",
    sudoeste: "",
    oeste: "",
    noroeste: "",
    minDistanceToActivate: 0,
    minSecBetweenCalls: 0,
    client: "",
    uuid:''

  });
  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  async function getBeacons () {
    await apiget(`/beacon/lista${"/"+client}`)
      .then(function (response) {
        const beacons = response?.data?.content;
        setBeacons(beacons);
        return beacons;
      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      })
      .then(function () {
        // sempre será executado
      });
  };
  async function postBeacons() {
    try {
      setLoading(true);
      await apipost(`/beacon`, {...beacon, client:client});
      alert("Beacon cadastrado com sucesso!");
      setLoading(false);
      getBeacons();
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert("Erro ao cadastrar o beacon.");
    }
  }




  const handleOpenDelete = (beaconId) => {
    setBeaconId(beaconId);
    setOpenDelete(true)
  };

  const handleRegister = () => {
    setButtonTitle("Cadastrar");
    setOpen(true);
  };

  const handleEdit = (beacon) => {
    console.log("edit", beacon)
    setBeacon(beacon);
    setBeaconId(beacon.beaconId);
    setButtonTitle("Editar");
    setOpenEdit(true);
  };

  async function deleteBeacons() {
    try {
      await apidelete(`/beacon/${beaconId}`);

      handleCloseDelete();
      alert("Beacon deletado!");
      getBeacons()
    } catch (error) {
      console.error(error);
      alert("Erro ao deletar o beacon.");
      handleCloseDelete();
    }
  };
  async function editBeacons() {
    setLoading(true);
    try {
      const response = await apiput(`/beacon/${beacon?.beaconId}`,  {...beacon, client:client});
      console.log(response);
      setLoading(false);
      alert('Beacon atualizado com sucesso!');
      getBeacons()

    } catch (error) {
      console.error(error);
      setLoading(false);
      alert('Ocorreu um erro ao atualizar o beacon.');
    }
  }
  return (
    <Tables>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>  <TableTitle>Beacons</TableTitle>
        <Button sx={{
          backgroundColor: "#30A54A",
          color: "white",
          borderRadius: "50px",
          "&:hover": {
            backgroundColor: "darkgreen",
          },
        }} onClick={() => handleRegister()}>+ Cadastrar
        </Button >
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: theme.colors.secondaryColor, color: theme.colors.white }}>
              <TableCell style={{ color: theme.colors.white }} align="left">beaconsId</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Descricao</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Nome</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Lat</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Lng</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Norte</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Nordeste</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Leste</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Sudeste</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Sul</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Sudoeste</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Oeste</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Noroeste</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Min distância</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Seg entre chamadas</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Cliente</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Uuid</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Editar</TableCell>
              <TableCell style={{ color: theme.colors.white }} align="left">Excluir</TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
          {loading ? ( 
      <TableRow>
      <TableCell colSpan={10} align="center">
      <div style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress />
        <Typography variant="body1" style={{ marginLeft: 10 }}>
          Aguarde, gerando Beacons...
        </Typography>
      </div>
    </TableCell>
    </TableRow>
       
      ) : (<>
            {beacons
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((beacon, index) => (
                <TableRow key={beacon?.beaconId}
                  style={{ animation: ` fadeInRightBig ${index * 0.5}s`, backgroundColor: index % 2 === 0 ? "#FFF" : "#F0F0F0" }}>
                  <TableCell align="left">{beacon?.beaconId}</TableCell>
                  <TableCell align="left">{beacon?.descricao.substring(0, 30) + '...'}</TableCell>
                  <TableCell align="left">{beacon?.nome}</TableCell>
                  <TableCell align="left">{beacon?.lat}</TableCell>
                  <TableCell align="left">{beacon?.lng}</TableCell>
                  <TableCell align="left">{beacon?.norte}</TableCell>
                  <TableCell align="left">{beacon?.nordeste}</TableCell>
                  <TableCell align="left">{beacon?.leste}</TableCell>
                  <TableCell align="left">{beacon?.sudeste}</TableCell>
                  <TableCell align="left">{beacon?.sul}</TableCell>
                  <TableCell align="left">{beacon?.sudoeste}</TableCell>
                  <TableCell align="left">{beacon?.oeste}</TableCell>
                  <TableCell align="left">{beacon?.noroeste}</TableCell>
                  <TableCell align="left">{beacon?.minDistanceToActivate}</TableCell>
                  <TableCell align="left">{beacon?.minSecBetweenCalls}</TableCell>
                  <TableCell align="left">{beacon?.client}</TableCell>
                  <TableCell align="left">{beacon?.uuid}</TableCell>
                  <TableCell align="center">
                    <EditButton onClick={() => handleEdit(beacon)}>
                      <EditIcon sx={{ color: '#fff' }} />
                    </EditButton>
                  </TableCell>
                  <TableCell align="center">
                    <DeleteButton onClick={() => handleOpenDelete(beacon?.beaconId)}>
                      <DeleteIcon sx={{ color: '#fff' }} />
                    </DeleteButton>
                  </TableCell>
                </TableRow>

              ))}</>)}
          </TableBody>
        </Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Ver tudo", value: -1 }]}
              colSpan={3}
              count={beacons?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Linhas por página",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>

      </TableContainer>
      <Modal
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <Button sx={{ position: 'absolute', top: 5, right: 15 }} onClick={() => setOpenEdit(false)}>Fechar

          </Button>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {modalTitle}
          </Typography>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              autoFocus
              unselectable="off"
              id="outlined-basic"
              label="Id"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, beaconId: e.target.value })}
              value={beacon.beaconId}
            />

            <TextField
              autoFocus
              unselectable="off"
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, nome: e.target.value })}
              value={beacon.nome}
            />
            <TextField
              id="outlined-basic"
              label="Descrição"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, descricao: e.target.value })}
              value={beacon.descricao}
            />
            <TextField
              id="outlined-basic"
              label="Foto"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, foto: e.target.value })}
              value={beacon.foto}
            />
            <TextField
              id="outlined-basic"
              label="Latitude"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, lat: e.target.value })}
              value={beacon.lat}
            />
            <TextField
              id="outlined-basic"
              label="Longitude"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, lng: e.target.value })}
              value={beacon.lng}
            />
            <TextField
              id="outlined-basic"
              label="Norte"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, norte: e.target.value })}
              value={beacon.norte}
            />
            <TextField
              id="outlined-basic"
              label="Nordeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, nordeste: e.target.value })}
              value={beacon.nordeste}
            />
            <TextField
              id="outlined-basic"
              label="Leste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, leste: e.target.value })}
              value={beacon.leste}
            />
            <TextField
              id="outlined-basic"
              label="Sudeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, sudeste: e.target.value })}
              value={beacon.sudeste}
            />
            <TextField
              id="outlined-basic"
              label="Sul"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, sul: e.target.value })}
              value={beacon.sul}
            />
            <TextField
              id="outlined-basic"
              label="Sudoeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, sudoeste: e.target.value })}
              value={beacon.sudoeste}
            />
            <TextField
              id="outlined-basic"
              label="Oeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, oeste: e.target.value })}
              value={beacon.oeste}
            />
            <TextField
              id="outlined-basic"
              label="Seg entre chamadas"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, minSecBetweenCalls: e.target.value })}
              value={beacon.minSecBetweenCalls}
            />
            <TextField
              id="outlined-basic"
              label="min distancia"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, minDistanceToActivate: e.target.value })}
              value={beacon.minDistanceToActivate}
            />
            <TextField
              id="outlined-basic"
              label="cliente"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, client: e.target.value })}
              value={beacon.client}
            />
  <TextField
              autoFocus
              unselectable="off"
              id="outlined-basic"
              label="Uuid"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, uuid: e.target.value })}
              value={beacon.uuid}
            />

            <RegisterButton disabled={loading}
              style={{ opacity: loading ? 0.6 : 1 }} onClick={() => editBeacons(beacon.beaconId)}>
              {loading ? "Aguarde..." : buttonTitle}
            </RegisterButton>


          </Box>
        </Box>

      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button sx={{ position: 'absolute', top: 5, right: 15 }} onClick={() => setOpen(false)}>Fechar

          </Button>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {modalTitle}
          </Typography>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              autoFocus
              unselectable="off"
              id="outlined-basic"
              label="Id"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, beaconId: e.target.value })}
              value={beacon.beaconId}
            />

            <TextField
              autoFocus
              unselectable="off"
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, nome: e.target.value })}
              value={beacon.nome}
            />
            <TextField
              id="outlined-basic"
              label="Descrição"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, descricao: e.target.value })}
              value={beacon.descricao}
            />
            <TextField
              id="outlined-basic"
              label="Foto"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, foto: e.target.value })}
              value={beacon.foto}
            />
            <TextField
              id="outlined-basic"
              label="Latitude"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, lat: e.target.value })}
              value={beacon.lat}
            />
            <TextField
              id="outlined-basic"
              label="Longitude"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, lng: e.target.value })}
              value={beacon.lng}
            />
            <TextField
              id="outlined-basic"
              label="Norte"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, norte: e.target.value })}
              value={beacon.norte}
            />
            <TextField
              id="outlined-basic"
              label="Nordeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, nordeste: e.target.value })}
              value={beacon.nordeste}
            />
            <TextField
              id="outlined-basic"
              label="Leste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, leste: e.target.value })}
              value={beacon.leste}
            />
            <TextField
              id="outlined-basic"
              label="Sudeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, sudeste: e.target.value })}
              value={beacon.sudeste}
            />
            <TextField
              id="outlined-basic"
              label="Sul"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, sul: e.target.value })}
              value={beacon.sul}
            />
            <TextField
              id="outlined-basic"
              label="Sudoeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, sudoeste: e.target.value })}
              value={beacon.sudoeste}
            />
            <TextField
              id="outlined-basic"
              label="Oeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, oeste: e.target.value })}
              value={beacon.oeste}
            />
            <TextField
              id="outlined-basic"
              label="Noroeste"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, noroeste: e.target.value })}
              value={beacon.noroeste}
            />
            <TextField
              id="outlined-basic"
              label="Seg entre chamadas"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, minSecBetweenCalls: e.target.value })}
              value={beacon.minSecBetweenCalls}
            />
            <TextField
              id="outlined-basic"
              label="min distancia"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, minDistanceToActivate: e.target.value })}
              value={beacon.minDistanceToActivate}
            />
            <TextField
              id="outlined-basic"
              label="Cliente"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, client: e.target.value })}
              value={beacon.client}
            />
            <TextField
              autoFocus
              unselectable="off"
              id="outlined-basic"
              label="Uuid"
              variant="outlined"
              onChange={(e) => setBeacon({ ...beacon, uuid: e.target.value })}
              value={beacon.uuid}
            />
            <RegisterButton disabled={loading}
              style={{ opacity: loading ? 0.6 : 1 }} onClick={postBeacons}>
                {loading ? "Aguarde..." : buttonTitle}
            </RegisterButton>


          </Box>
        </Box>

      </Modal>
      <Modal
        open={imageOpen}
        onClose={() => { setImageOpen(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <img style={{ width: '80%', height: '80%' }} src={modalImageUrl} alt={''}/>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Deletar Beacon
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deseja realmente deletar esse Beacon?
          </Typography>
          <NoButton onClick={handleCloseDelete}>
            Não
          </NoButton>
          <YesButton onClick={() => deleteBeacons()}>
            Sim
          </YesButton>

        </Box>
      </Modal>

    </Tables>
  )
}